export function RecruitfulIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100"
			height="100"
			viewBox="0 0 100 100"
			fill="none"
		>
			<path
				d="M65.18 13H35.36C23.0109 13 13 23.0109 13 35.36V65.18C13 77.5291 23.0109 87.54 35.36 87.54H65.18C77.5291 87.54 87.54 77.5291 87.54 65.18V35.36C87.54 23.0109 77.5291 13 65.18 13Z"
				fill="#D5EEF2"
			/>
			<path
				d="M38.35 45.56C41.21 45.56 43.63 45.69 44.47 46.07C45.1 46.35 45.46 47.13 45.65 48.15C46.46 52.48 43.11 56.48 38.72 56.65C36.91 56.72 34.94 56.77 33.14 56.77C30.49 56.77 28.2 56.66 27.32 56.32C25.29 55.54 25.01 50.42 25.07 47.69C25.09 46.78 25.8 46.03 26.72 45.97C29.21 45.82 34.23 45.56 38.35 45.56ZM38.35 42.82C34.19 42.82 29.18 43.08 26.56 43.23C24.23 43.37 22.38 45.3 22.33 47.63C22.31 48.8 22.33 51.09 22.72 53.22C22.93 54.35 23.56 57.8 26.34 58.87C27.16 59.18 28.6 59.5 33.14 59.5C34.76 59.5 36.67 59.46 38.82 59.38C41.77 59.27 44.51 57.88 46.35 55.58C48.15 53.33 48.88 50.44 48.35 47.63C47.97 45.58 47.04 44.2 45.6 43.55C44.74 43.16 43.27 42.8 38.35 42.8V42.82Z"
				fill="url(#paint0_linear_2933_97)"
			/>
			<path
				d="M38.35 45.56C41.21 45.56 43.63 45.69 44.47 46.07C45.1 46.35 45.46 47.13 45.65 48.15C46.46 52.48 43.11 56.48 38.72 56.65C36.91 56.72 34.94 56.77 33.14 56.77C30.49 56.77 28.2 56.66 27.32 56.32C25.29 55.54 25.01 50.42 25.07 47.69C25.09 46.78 25.8 46.03 26.72 45.97C29.21 45.82 34.23 45.56 38.35 45.56ZM38.35 42.82C34.19 42.82 29.18 43.08 26.56 43.23C24.23 43.37 22.38 45.3 22.33 47.63C22.31 48.8 22.33 51.09 22.72 53.22C22.93 54.35 23.56 57.8 26.34 58.87C27.16 59.18 28.6 59.5 33.14 59.5C34.76 59.5 36.67 59.46 38.82 59.38C41.77 59.27 44.51 57.88 46.35 55.58C48.15 53.33 48.88 50.44 48.35 47.63C47.97 45.58 47.04 44.2 45.6 43.55C44.74 43.16 43.27 42.8 38.35 42.8V42.82Z"
				fill="url(#paint1_linear_2933_97)"
			/>
			<path
				d="M63.85 45.56C67.97 45.56 72.99 45.82 75.48 45.97C76.39 46.03 77.11 46.77 77.13 47.69C77.18 50.42 76.91 55.54 74.88 56.32C74 56.66 71.72 56.77 69.06 56.77C67.26 56.77 65.3 56.72 63.48 56.65C59.08 56.49 55.73 52.48 56.55 48.15C56.74 47.14 57.1 46.35 57.73 46.07C58.57 45.69 60.99 45.56 63.85 45.56ZM63.85 42.82C58.93 42.82 57.46 43.18 56.6 43.57C55.16 44.22 54.24 45.59 53.85 47.65C53.33 50.45 54.05 53.35 55.85 55.6C57.69 57.91 60.43 59.29 63.38 59.4C65.53 59.48 67.44 59.52 69.06 59.52C73.6 59.52 75.05 59.2 75.86 58.89C78.64 57.82 79.27 54.37 79.48 53.24C79.87 51.1 79.89 48.82 79.87 47.65C79.82 45.32 77.96 43.38 75.64 43.25C73.02 43.1 68.01 42.84 63.85 42.84V42.82Z"
				fill="url(#paint2_linear_2933_97)"
			/>
			<path
				d="M55.96 45.69L56.42 45.26L55.99 44.8L56.4 44.31L55.76 43.83C55.69 43.83 55.66 43.83 55.58 43.83C55.45 43.82 55.39 43.83 55.26 43.81C54.03 43.64 52.98 43.41 51.72 43.35C51.53 43.35 51.34 43.34 51.15 43.35C50.5 43.35 50.08 43.4 50.08 43.4C49.96 43.37 49.78 43.4 49.65 43.41C49.44 43.44 49.24 43.44 49.04 43.44C48.82 43.44 48.6 43.49 48.38 43.52C48.14 43.56 47.91 43.59 47.67 43.63C47.2 43.7 46.72 43.76 46.24 43.83C46.18 43.83 45.88 43.81 45.88 43.92C45.85 43.97 45.78 44.07 45.78 44.14C45.78 44.19 45.72 44.27 45.7 44.3L45.57 44.58C45.57 44.58 45.54 44.63 45.54 44.65C45.54 44.7 45.62 44.79 45.65 44.83L46 45.41C46.04 45.48 45.98 45.58 45.95 45.65C45.86 45.88 45.77 46.11 45.68 46.33L46.19 47.05C46.19 47.05 46.21 47.08 46.21 47.1C46.21 47.11 46.21 47.13 46.21 47.14C46.12 47.42 46.03 47.71 45.94 47.99L46.54 48.71L46.34 49.64L47.02 50.27L46.97 51.22L47.21 51.36C47.46 51.25 47.7 51.18 47.95 51.13C47.99 51.11 48.03 51.11 48.08 51.1L48.32 51.06L48.99 50.98L49.33 50.96C49.6 50.96 50.12 50.93 50.48 50.9C52.09 50.9 53.2 51.27 54.63 51.7L54.97 51.49L54.74 50.81L55.32 50.41L55.06 49.74L55.63 49.32L55.35 48.66L55.9 48.23L55.61 47.57L56.15 47.13L55.84 46.58L56.35 46.21L55.98 45.7L55.96 45.69Z"
				fill="#EC9322"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2933_97"
					x1="35.42"
					y1="49.72"
					x2="35.42"
					y2="69.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="#6FC7B3" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2933_97"
					x1="54.93"
					y1="27.28"
					x2="9.37001"
					y2="79.36"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#27788C" />
					<stop offset="1" stopColor="#08BAAF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_2933_97"
					x1="86.84"
					y1="27.76"
					x2="41.29"
					y2="79.84"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#27788C" />
					<stop offset="1" stopColor="#08BAAF" />
				</linearGradient>
			</defs>
		</svg>
	)
}
