import {
	BoardColumEnum,
	DEFAULT_SELECTED_COLUMNS,
	IEmojiProps,
	IFavoriteBoard,
	Query,
} from '@touchpoints/requests'
import { makeAutoObservable, runInAction } from 'mobx'
import type { RootStore } from './root'

import { GroupByFieldsEnum } from '@hooks/cards'
import { fetchCardsForFavoriteBoard, fetchCardsForPublicBoard } from '@requests/cards'
import {
	createFavoriteBoard,
	fetchFavoriteBoard,
	fetchFavoriteBoards,
	removeFavoriteBoard,
	subscribeToFavoriteBoard,
	unsubscribeFromFavoriteBoard,
	updateFavoriteBoard,
} from '@requests/favoriteBoards'
import { createAsyncQueue } from '@touchpoints/async-queue'

class FavoriteBoard implements IFavoriteBoard {
	id = ''
	organizationId = ''
	userOrgId = ''
	isPrivate = true
	name = ''
	emojiProps: IEmojiProps = { emoji: '', emojiUrl: '' }
	query: Query = { conjunction: 'and', conditions: [] }
	groupBy: string[] = []
	selectedColumns: BoardColumEnum[] = []
	subscribedUsers: string[] = []
	shareIds: string[] = []

	constructor(data: IFavoriteBoard) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.userOrgId = data.userOrgId
		this.isPrivate = data.isPrivate
		this.name = data.name
		this.emojiProps = data.emojiProps
		this.query = data.query ? JSON.parse(data.query as unknown as string) : undefined
		this.groupBy = data.groupBy ?? []
		this.selectedColumns = data.selectedColumns ?? DEFAULT_SELECTED_COLUMNS
		this.subscribedUsers = data.subscribedUsers ?? []
		this.shareIds = data.shareIds ?? []

		makeAutoObservable(this)
	}
}

export class FavoriteBoardsStore {
	private readonly root: RootStore
	private requestQueue = createAsyncQueue({ maxChannels: 3 })

	readonly list: IFavoriteBoard[] = []
	private _loading = false

	activeFavoriteBoardId = ''

	get isLoading() {
		return this._loading
	}

	get activeFavoriteBoard() {
		return this.list.find((t) => t.id === this.activeFavoriteBoardId)
	}

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	setActiveFavoriteBoardId(id: string) {
		this.activeFavoriteBoardId = id
	}

	async fetchPublicCards(orgSlug: string, shareId: string) {
		const res = await this.requestQueue.addUnique(
			`fetch-public-cards-${shareId}`,
			() => fetchCardsForPublicBoard(orgSlug, shareId),
			{ priority: 1 }
		)

		if (!res.data) {
			return
		}

		return res.data
	}

	async fetchCards(orgId: string, favoriteBoardId: string) {
		const res = await this.requestQueue.addUnique(
			`fetch-cards-for-favorite-${favoriteBoardId}`,
			() => fetchCardsForFavoriteBoard(orgId, favoriteBoardId),
			{ priority: 1 }
		)

		if (!res.data) {
			return
		}

		return res.data
	}

	async fetchFavoriteBoard(favoriteBoardId: string) {
		const userOrg = this.root.organizationUsers.activeUser
		if (!userOrg) {
			return
		}
		this.setLoading(true)
		const res = await fetchFavoriteBoard(userOrg.organizationId, favoriteBoardId)
		this.setLoading(false)

		if (!res) {
			return
		}

		const favoriteBoard = res.data
		if (!favoriteBoard) {
			return
		}

		this.addFavoriteBoard(favoriteBoard)
		return this.getById(favoriteBoard.id)
	}

	async fetchFavoriteBoards() {
		const userOrg = this.root.organizationUsers.activeUser
		if (!userOrg) {
			return
		}
		this.setLoading(true)
		const res = await this.requestQueue.addUnique(`fetch-position-candidates`, () =>
			fetchFavoriteBoards(userOrg.organizationId)
		)
		this.setLoading(false)

		if (!res) {
			return
		}

		const favoriteBoards = res.data ?? []

		for (const favoriteBoard of favoriteBoards) {
			this.addFavoriteBoard(favoriteBoard)
		}
	}

	async create(
		boardName: string,
		emojiProps: IEmojiProps,
		isPrivate: boolean,
		query: Query,
		groupBy: GroupByFieldsEnum[],
		selectedColumns: BoardColumEnum[]
	): Promise<{ success: boolean; message?: string }> {
		const userOrg = this.root.organizationUsers.activeUser
		if (!userOrg) {
			console.error('could not find user')
			return { success: false }
		}

		const res = await createFavoriteBoard({
			orgId: userOrg.organizationId,
			boardName,
			emojiProps,
			isPrivate,
			query: JSON.stringify(query),
			groupBy,
			selectedColumns,
		})
		if (!res) {
			return { success: false }
		}

		const favoriteBoard = res.data
		if (!favoriteBoard) {
			return { success: false, message: res.message }
		}

		this.addFavoriteBoard(favoriteBoard)

		return { success: true }
	}

	async update(
		favoriteBoardId: string,
		boardName: string,
		emojiProps: IEmojiProps,
		isPrivate: boolean,
		query: Query,
		groupBy: GroupByFieldsEnum[],
		selectedColumns: BoardColumEnum[],
		shareIds?: string[]
	): Promise<{ success: boolean; message?: string }> {
		const user = this.root.organizationUsers.activeUser
		if (!user) {
			console.error('could not find user')
			return { success: false }
		}

		const res = await updateFavoriteBoard({
			orgId: user.organizationId,
			id: favoriteBoardId,
			query: JSON.stringify(query),
			isPrivate,
			emojiProps,
			boardName,
			groupBy,
			selectedColumns,
			shareIds,
		})
		if (!res) {
			return { success: false }
		}

		const favoriteBoard = res.data
		if (!favoriteBoard) {
			return { success: false, message: res.message }
		}

		this.addFavoriteBoard(favoriteBoard)

		return { success: true }
	}

	async remove(favoriteBoardId: string): Promise<{ success: boolean; message?: string }> {
		const userOrg = this.root.organizationUsers.activeUser
		if (!userOrg) {
			console.error('could not find user')
			return { success: false }
		}

		const res = await removeFavoriteBoard(userOrg.organizationId, favoriteBoardId)
		if (!res) {
			return { success: false }
		}

		const data = res.data
		if (!data) {
			return { success: false, message: res.message }
		}

		this.removeFavoriteBoard(favoriteBoardId)

		return { success: true }
	}

	async subscribe(favoriteBoardId: string): Promise<{ success: boolean; message?: string }> {
		const userOrg = this.root.organizationUsers.activeUser
		if (!userOrg) {
			console.error('could not find user')
			return { success: false }
		}

		const board = this.getById(favoriteBoardId)
		if (!board) return { success: false }

		const res = await subscribeToFavoriteBoard(userOrg.organizationId, favoriteBoardId)
		if (!res) {
			return { success: false }
		}

		const data = res.data
		if (!data) {
			return { success: false, message: res.message }
		}

		runInAction(() => {
			board.subscribedUsers.push(userOrg.id)
		})
		return { success: true }
	}

	async unsubscribe(favoriteBoardId: string): Promise<{ success: boolean; message?: string }> {
		const userOrg = this.root.organizationUsers.activeUser
		if (!userOrg) {
			console.error('could not find user')
			return { success: false }
		}
		const board = this.getById(favoriteBoardId)
		if (!board) return { success: false }

		const res = await unsubscribeFromFavoriteBoard(userOrg.organizationId, favoriteBoardId)
		if (!res) {
			return { success: false }
		}

		const data = res.data
		if (!data) {
			return { success: false, message: res.message }
		}

		runInAction(() => {
			board.subscribedUsers = board.subscribedUsers.filter((e) => e !== userOrg.id)
		})
		return { success: true }
	}

	getById(favoriteBoardId: string) {
		return this.list.find((t) => t.id === favoriteBoardId)
	}

	private addFavoriteBoard(favoriteBoard: IFavoriteBoard) {
		const existing = this.getById(favoriteBoard.id)

		runInAction(() => {
			if (existing) {
				Object.assign(existing, new FavoriteBoard(favoriteBoard))
			} else {
				this.list.push(new FavoriteBoard(favoriteBoard))
			}
		})
	}

	private removeFavoriteBoard(id: string) {
		const index = this.list.findIndex((t) => t.id === id)

		if (index < 0) {
			return
		}
		runInAction(() => {
			this.list.splice(index, 1)
		})
	}

	private setLoading(v: boolean) {
		this._loading = v
	}
}
