import { rootStore } from '@store/index'
import { ICard } from '@touchpoints/requests'
import _, { ValueIteratee } from 'lodash'
import { useMemo } from 'react'

export enum GroupByFieldsEnum {
	'account' = 'account',
	'candidate' = 'candidate',
	'position' = 'position',
	'stage' = 'stage',
	'subStatus' = 'subStatus',
	'engagementContactId' = 'engagementContactId',
	'recruiterId' = 'recruiterId',
	'accountExecutiveId' = 'accountExecutiveId',
	'supportContactId' = 'supportContactId',
}

const groupers: Record<GroupByFieldsEnum, ValueIteratee<ICard>> = {
	account: (card: ICard) => card.account.id,
	candidate: (card: ICard) => card.candidate.id,
	position: (card: ICard) => card.position.id,
	stage: (card: ICard) => card.stage,
	subStatus: (card: ICard) => card.subStatus,
	engagementContactId: (card: ICard) => card.engagementContactId,
	recruiterId: (card: ICard) => card.recruiterId,
	accountExecutiveId: (card: ICard) => card.accountExecutiveId,
	supportContactId: (card: ICard) => card.supportContactId,
}

export interface IGroupedCard {
	key: string
	value: string
	records: ICard[] | IGroupedCard[]
}

function groupCards(cards: ICard[], groupBy: GroupByFieldsEnum): IGroupedCard[] {
	const grouper = groupers[groupBy]
	if (!grouper) {
		throw new Error(`Invalid group by key: ${groupBy}`)
	}

	const grouped = _.groupBy(cards, grouper)
	const entries = Object.entries(grouped)
	const response = []

	for (const [value, records] of entries) {
		const sortedEntiresByPosition = (records: ICard[]) => {
			if (!records) {
				return []
			}

			return records.sort((a, b) => {
				const aPosition = rootStore.positions.getPositionById(a.position.id)
				const bPosition = rootStore.positions.getPositionById(b.position.id)
				if (!aPosition || !bPosition) {
					return 0
				}
				return bPosition.referenceNumber - aPosition.referenceNumber
			})
		}
		response.push({ key: groupBy, value, records: sortedEntiresByPosition(records) })
	}

	return response
}

export function isGroupedCards(data: IGroupedCard | ICard): data is IGroupedCard {
	return (data as IGroupedCard).records !== undefined
}

export function useGroupedCards(
	cards: ICard[],
	groupByFields: GroupByFieldsEnum[] = []
): IGroupedCard {
	const groupedCards = useMemo(() => {
		const recursiveGroup = (
			cards: ICard[],
			groupByFields: GroupByFieldsEnum[]
		): IGroupedCard[] => {
			if (groupByFields.length === 0) {
				return cards as unknown as IGroupedCard[]
			}

			const groupBy = groupByFields[0]
			const grouped = groupCards(cards, groupBy)

			for (const group of grouped) {
				group.records = recursiveGroup(group.records as ICard[], groupByFields.slice(1))
			}

			return grouped
		}

		return {
			key: 'root',
			value: 'root',
			records: recursiveGroup(cards, groupByFields),
		}
	}, [cards, groupByFields])

	return groupedCards
}
