import { IOrgData } from '@components/accounts/GroupedCards'
import { authHeader, get, post } from './api'

import type { ICard, IFavoriteBoard, Query } from '@touchpoints/requests'

export async function fetchCardsWithQuery(
	orgId: string,
	query: Query,
	page?: number,
	limit?: number
) {
	const headers = await authHeader()
	let url = `/organizations/${orgId}/cards/search`
	if (page && limit) {
		url += `?page=${page}&limit=${limit}`
	}

	return await post<{
		cards: ICard[]
		currentPage: number
		hasNextPage: boolean
		totalPages: number
		totalItems: number
	}>(url, { query: JSON.stringify(query) }, headers)
}

export async function fetchCardsForPublicBoard(orgSlug: string, shareId: string) {
	const headers = await authHeader()
	const response = await get<{
		cards: ICard[]
		board: IFavoriteBoard
		orgData: IOrgData
	}>(`/organizations/${orgSlug}/board/${shareId}/cards`, undefined, headers)

	return response
}

export async function fetchCardsForFavoriteBoard(orgId: string, favoriteBoardId: string) {
	const headers = await authHeader()
	const response = await get<{ cards: ICard[] }>(
		`/organizations/${orgId}/favorites/${favoriteBoardId}/cards`,
		undefined,
		headers
	)

	return response
}

export async function fetchCardsForPosition(orgId: string, positionId: string) {
	const headers = await authHeader()
	const response = await get<{ cards: ICard[] }>(
		`/organizations/${orgId}/positions/${positionId}/cards`,
		undefined,
		headers
	)

	return response
}
