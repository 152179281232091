import { HiPlus, HiTrash } from 'react-icons/hi'
import { Button, Row, Tooltip, Text, Spacer, Loading } from '@nextui-org/react'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { makeAutoObservable } from 'mobx'

import { Table, Modal, Select, createSelectOnChange, useConfirm } from '@touchpoints/ui'

import type { ICandidate, ISequence, ISequenceInstance } from '@touchpoints/requests'

import { rootStore } from '@store'
import { removeCandidateFromSequence } from '@actions'
import { useEffect, useState } from 'react'
import flatten from 'lodash/flatten'
import { useReaction, useRefreshWhen } from '@touchpoints/mobx-hooks'
import { useCountdown } from 'usehooks-ts'
import { InstanceRowNextStep } from '@components/sequences/InstanceNextStep'
import format from 'date-fns/format'

const modalStore = makeAutoObservable({
	isOpen: false,
	close() {
		this.isOpen = false
	},
	open() {
		this.isOpen = true
	},
})

function useDelay(secs: number) {
	const [count, { startCountdown }] = useCountdown({
		countStart: secs,
		intervalMs: 1000,
	})

	useEffect(() => {
		startCountdown()
	}, [startCountdown])

	return count > 0
}
const SequenceSearchModal = observer(function ({ candidate }: { candidate: ICandidate }) {
	const [sequenceId, setSequenceId] = useState('')
	const [positionId, setPositionId] = useState('')
	const [mailboxId, setMailboxId] = useState('')
	const [loading, setLoading] = useState(false)

	const recruiterMailboxes = flatten(
		rootStore.organizationUsers.usersForActiveOrg
			.filter((user) => user.mailboxes.length > 0)
			.map((user) =>
				user.mailboxes.map((m) => ({
					id: user.userId,
					firstName: user.firstName,
					lastName: user.lastName,
					email: m.email,
				}))
			)
	)

	const recruiterOptions = recruiterMailboxes.map((r, idx) => ({
		id: r.id,
		email: r.email,
		label: `${r.email} (${r.firstName || 'No'} ${r.lastName || 'Name'})`,
		value: `${idx}`,
	}))

	const handleAddToSequence = async () => {
		const orgId = rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		if (!positionId || !sequenceId) {
			return
		}

		const selectedOption = recruiterOptions.find((m) => m.value === mailboxId)
		if (!selectedOption) {
			return
		}

		setLoading(true)

		// NOTE: this is duplicated in Candidates.tsx
		// update position candidates
		// TODO: may want to throttle this
		const cards = await rootStore.positions.fetchCardsForPosition(positionId)

		// look for positionCandidate and add if not exist
		const card = cards.find(
			(c) => c.candidate.id === candidate.id && c.position.id === positionId
		)

		if (!card) {
			const position = rootStore.positions.getPositionById(positionId)
			if (position) {
				await rootStore.positions.createPositionCandidate(position, candidate.id)
			}
		}

		const mailbox = recruiterMailboxes.find(
			(m) => m.id === selectedOption.id && m.email === selectedOption.email
		)

		const res = await rootStore.sequences.addCandidateToSequence(
			sequenceId,
			candidate.id,
			positionId,
			{
				senderId: mailbox?.id,
				senderEmail: mailbox?.email,
			}
		)

		if (!res.success) {
			console.error(res.message)
			setLoading(false)
			return
		}

		setSequenceId('')
		setPositionId('')
		modalStore.close()
		setLoading(false)
	}

	const positionOptions = rootStore.positions.groupedSelectOptions

	return (
		<Modal blur isOpen={modalStore.isOpen} onClose={() => modalStore.close()}>
			<Modal.Body>
				<Select
					label="Sequence"
					placeholder="search for sequence..."
					menuPortalTarget={document.body}
					isClearable={true}
					options={rootStore.sequences.list}
					getOptionValue={(s) => s.id}
					getOptionLabel={(s) => s.name}
					filterKeys={['name']}
					onChange={(val) => {
						if (!val) {
							return
						}
						setSequenceId(val.id)
					}}
				/>
				<Select
					label="Position"
					placeholder="search for position..."
					menuPortalTarget={document.body}
					isClearable={true}
					value={positionOptions
						.find((o) => o.positionId === positionId)
						?.options.find((o) => o.value === positionId)}
					options={positionOptions}
					onChange={createSelectOnChange((v) => {
						setPositionId(v)
					})}
				/>
				<Select
					label="Sequenced By"
					menuPortalTarget={document.body}
					value={recruiterOptions.find((r) => r.value === mailboxId)}
					getOptionValue={(u) => u.value}
					getOptionLabel={(u) => u.label}
					options={recruiterOptions}
					onChange={createSelectOnChange((v) => {
						setMailboxId(v)
					})}
				/>
				<Button disabled={loading} onClick={handleAddToSequence}>
					{loading ? <Loading /> : 'Add'}
				</Button>
			</Modal.Body>
		</Modal>
	)
})

type Props = {
	candidateId: string
	positionId?: string
	canAddToSequence?: boolean
}

type Pair = {
	instance: ISequenceInstance
	sequence: ISequence
}

export function CandidateSequences({ candidateId, positionId, canAddToSequence = true }: Props) {
	const router = useRouter()
	const confirm = useConfirm()

	const pc = useReaction(() => {
		if (!candidateId || !positionId) {
			return
		}
		return rootStore.positions.getPositionCandidate(candidateId, positionId)
	})

	useRefreshWhen(
		() => rootStore.organizations.activeOrganizationId,
		async () => {
			if (!pc) {
				return
			}

			if (!candidateId || !positionId) {
				return
			}

			// get active instances
			await rootStore.sequences.fetchSequenceInstancesForPositionCandidate(pc.id)

			// then get any completed or paused ones
			await Promise.all([
				rootStore.sequences.fetchSequenceInstancesForCandidate(candidateId, 'completed'),
				rootStore.sequences.fetchSequenceInstancesForCandidate(candidateId, 'paused'),
			])
		},
		[pc, candidateId, positionId]
	)

	useRefreshWhen(
		() => rootStore.organizations.activeOrganizationId,
		async () => {
			if (positionId) {
				return
			}

			// get active instances
			await rootStore.sequences.fetchSequenceInstancesForCandidate(candidateId)

			// then get any completed or paused ones
			await Promise.all([
				rootStore.sequences.fetchSequenceInstancesForCandidate(candidateId, 'completed'),
				rootStore.sequences.fetchSequenceInstancesForCandidate(candidateId, 'paused'),
			])
		},
		[positionId, candidateId]
	)

	const candidate = useReaction(() => {
		return rootStore.candidates.getCandidateById(candidateId)
	})

	const handleRemoveFromSequence = async (sequence: ISequence, instance: ISequenceInstance) => {
		if (!candidateId) {
			return
		}

		const shouldContinue = await confirm({
			title: 'Are you sure?',
			message: 'This will remove the candidate from the sequence.',
			confirmText: 'Remove',
			confirmColor: 'error',
			cancelText: 'Cancel',
		})

		if (!shouldContinue) {
			return
		}

		const candidate = rootStore.candidates.getCandidateById(candidateId)
		if (!candidate) {
			return
		}
		return removeCandidateFromSequence(candidate, sequence, instance.id)
	}

	const columns = [
		{
			name: 'Name',
			selector: (row: Pair) => row.sequence.name,
		},
		{
			name: 'Next Step',
			cell: (row: Pair) => <InstanceRowNextStep instance={row.instance} />,
			width: '200px',
		},
		{
			name: 'Status',
			cell: (row: Pair) => {
				const { status, completedAction } = row.instance
				let statusStr = status.toString()
				if (status === 'completed') {
					const date = row.instance.completedAt
					const dateStr = date ? format(new Date(date), 'MMM do, yyyy h:mmaaa') : ''
					if (dateStr) {
						statusStr = `Completed on ${dateStr}`
					}
					switch (completedAction) {
						case 'booked-meeting':
							statusStr = `${statusStr} (Booked Meeting)`
							break
						case 'replied':
							statusStr = `${statusStr} (Replied)`
							break
						case 'failed':
							statusStr = `${statusStr} (Failed)`
							break
					}
				}
				return <div className="flex">{statusStr}</div>
			},
		},
		{
			name: 'From Email',
			selector: (row: Pair) => row.instance.senderEmail ?? 'Unknown',
		},
		{
			name: 'Actions',
			cell: (row: Pair) => (
				<Tooltip content="Remove" placement="left" offset={-10}>
					<Button
						auto
						light
						color="error"
						icon={<HiTrash />}
						onClick={() => handleRemoveFromSequence(row.sequence, row.instance)}
						disabled={row.instance.status === 'completed'}
					/>
				</Tooltip>
			),
			width: '150px',
		},
	]

	const sequences = useReaction(
		() => {
			if (positionId) {
				return rootStore.sequences
					.getSequenceInstancesByCandidateAndPosition(candidateId, positionId)
					.map((instance) => {
						return {
							instance,
							sequence: rootStore.sequences.getSequenceById(instance.sequenceId),
						}
					})
					.filter((d) => !!d.sequence) as Pair[]
			}

			return rootStore.sequences
				.getSequenceInstanceByCandidateId(candidateId)
				.map((instance) => {
					return {
						instance,
						sequence: rootStore.sequences.getSequenceById(instance.sequenceId),
					}
				})
				.filter((d) => !!d.sequence) as Pair[]
		},
		100,
		[candidateId, positionId]
	)

	const isDelayed = useDelay(3)

	if (isDelayed && sequences.length <= 0) {
		return (
			<div className="flex justify-center items-center h-80">
				<Loading />
			</div>
		)
	}

	return (
		<>
			{!sequences || sequences.length <= 0 ? (
				<Row justify="center">
					<Text>No sequences.</Text>
				</Row>
			) : (
				<Table
					columns={columns}
					rows={sequences}
					onRowClicked={(row) => router.push(`/sequences/${row.sequence.id}`)}
				/>
			)}
			<Spacer />
			{canAddToSequence && candidate && (
				<Row justify="flex-end">
					<Button size="sm" auto icon={<HiPlus />} onClick={() => modalStore.open()}>
						Sequence
					</Button>
					<SequenceSearchModal candidate={candidate} />
				</Row>
			)}
		</>
	)
}
