import { CandidateStageTimeline } from '@components/candidates/CandidateStageTimeline'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { useReaction } from '@hooks/mobx'
import { formatTaskDate } from '@services/date'
import { rootStore } from '@store'
import { ICard } from '@touchpoints/requests'
import { Pill, toast } from '@touchpoints/ui'
import { memo, useEffect, useRef } from 'react'
import { HiOutlineMail } from 'react-icons/hi'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { useIntersectionObserver } from 'usehooks-ts'
import { useDueTimeColor } from './hooks'
import { store } from './store'

import { Tooltip } from '@nextui-org/react'
import clsx from 'clsx'
import { FaRegComment } from 'react-icons/fa'

type CardProps = {
	card: ICard
	showAsCard?: boolean
	onClick?: () => void
}
export const Card = memo(function Card({ card, showAsCard, onClick }: CardProps) {
	const ref = useRef<HTMLDivElement>(null)
	const entry = useIntersectionObserver(ref, { threshold: 0 })
	const candidate = card.candidate
	const dueByColor = useDueTimeColor(card.nextEvent?.date)
	const activePositionCandidateId = useReaction(() => store.activePositionCandidateId)
	const recruiter = card.recruiterId
		? rootStore.organizationUsers.users[card.recruiterId]
		: undefined

	useEffect(() => {
		// auto scroll only if position candidate is selected
		if (activePositionCandidateId === card.id) {
			const element = document.getElementById(`board-card-${store.activePositionCandidateId}`)
			element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
		}
	}, [activePositionCandidateId, card.id])

	return (
		<div
			id={showAsCard ? '' : `board-card-${card.id}`}
			ref={ref}
			className={clsx(
				'flex flex-col w-full p-3 space-y-4 cursor-pointer hover:bg-[#f1f3f5]/50',
				{
					'rounded-md border shadow-sm': showAsCard,
					'border-t last:border-b': !showAsCard,
					'bg-[#f1f3f5]': card.id === store.activePositionCandidateId,
					'opacity-60':
						store.activePositionCandidateId &&
						card.id !== store.activePositionCandidateId,
				}
			)}
			onClick={onClick}
		>
			<div className="h-[100px]">
				{entry?.isIntersecting && (
					<>
						<div className="flex justify-between items-center">
							<div className="line-clamp-1">
								<CandidateStageTimeline
									currentStageId={card.stage}
									stageHistory={card.stageHistory}
								/>
							</div>
							{recruiter && !showAsCard && (
								<OrgUserAvatar size="xs" user={recruiter} />
							)}
						</div>

						<div className="flex flex-col space-y-2">
							<div className="flex w-full justify-between">
								<p className="text-sm font-medium truncate">
									{`${candidate.firstName} ${candidate.lastName}`}
								</p>
							</div>

							<div className="flex w-full items-center">
								{candidate?.phone && isValidPhoneNumber(candidate?.phone) && (
									<p className="text-sm text-gray-500 truncate mr-2">
										{formatPhoneNumber(candidate.phone)}
									</p>
								)}
								{candidate?.email && (
									<Tooltip content={candidate?.email}>
										<HiOutlineMail
											className="text-gray-500 cursor-pointer"
											onClick={async (e) => {
												e.stopPropagation()
												navigator.clipboard.writeText(candidate.email)
												toast.success(`Copied to clipboard!`)
											}}
										/>
									</Tooltip>
								)}
							</div>
							{showAsCard && (
								<div className="flex w-full justify-between items-center">
									<div className="flex space-x-2 items-center">
										{card.commentsLength > 0 && (
											<div className="flex items-center space-x-1 text-gray-500">
												<FaRegComment />
												<p>{card.commentsLength}</p>
											</div>
										)}
										{card.nextEvent && (
											<Pill size="xs" className="p-1" color={dueByColor}>
												{formatTaskDate(card.nextEvent.date)}
											</Pill>
										)}
									</div>
									{recruiter && <OrgUserAvatar size="xs" user={recruiter} />}
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	)
})
