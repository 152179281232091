import { ICandidate, IPositionCandidate, PostMethodFunc } from '../types'

export function makeSaveSourcedCandidate(
	postMethod: PostMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function saveSourcedCandidate(
		orgId: string,
		linkedinUrl: string,
		positionId: string,
		options: { linkedinRecruiterUrl?: string; tags?: string[] } = {}
	) {
		const headers = await authHeader()
		return postMethod<{ output?: string; candidate?: ICandidate; card?: IPositionCandidate }>(
			`/organizations/${orgId}/sourcing`,
			{ linkedinUrl, positionId, ...options },
			headers
		)
	}
}

export function makeQuickReplySelected(
	postMethod: PostMethodFunc,
	authHeader: () => Promise<Record<string, string>>
) {
	return async function quickReplySelected(orgId: string, cardId: string, replyId: string) {
		const headers = await authHeader()
		return postMethod<{ success: boolean }>(
			`/organizations/${orgId}/extension/${cardId}/instant-reply/${replyId}`,
			undefined,
			headers
		)
	}
}
