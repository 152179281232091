import { GroupByFieldsEnum } from '@hooks/cards'
import { authHeader, del, get, post, put } from './api'

import type { BoardColumEnum, IEmojiProps, IFavoriteBoard } from '@touchpoints/requests'

export async function fetchFavoriteBoard(orgId: string, id: string) {
	const headers = await authHeader()
	return get<IFavoriteBoard>(`/organizations/${orgId}/favorite-boards/${id}`, undefined, headers)
}

export async function fetchFavoriteBoards(orgId: string) {
	const headers = await authHeader()
	return get<IFavoriteBoard[]>(`/organizations/${orgId}/favorite-boards/`, undefined, headers)
}

export async function createFavoriteBoard({
	orgId,
	boardName,
	emojiProps,
	isPrivate,
	query,
	groupBy,
	selectedColumns,
}: {
	orgId: string
	boardName: string
	emojiProps: IEmojiProps
	isPrivate: boolean
	query: string
	groupBy: GroupByFieldsEnum[]
	selectedColumns: BoardColumEnum[]
}) {
	const headers = await authHeader()
	return post<IFavoriteBoard>(
		`/organizations/${orgId}/favorite-boards/`,
		{ boardName, emojiProps, isPrivate, query, groupBy, selectedColumns },
		headers
	)
}

export async function updateFavoriteBoard({
	id,
	orgId,
	boardName,
	emojiProps,
	isPrivate,
	query,
	groupBy,
	selectedColumns,
	shareIds,
}: {
	id: string
	orgId: string
	boardName: string
	emojiProps: IEmojiProps
	isPrivate: boolean
	query: string
	groupBy: GroupByFieldsEnum[]
	selectedColumns: BoardColumEnum[]
	shareIds?: string[]
}) {
	const headers = await authHeader()
	return put<IFavoriteBoard>(
		`/organizations/${orgId}/favorite-boards/${id}`,
		{ boardName, emojiProps, isPrivate, query, groupBy, selectedColumns, shareIds },
		headers
	)
}

export async function removeFavoriteBoard(orgId: string, id: string) {
	const headers = await authHeader()
	return del<{ success: boolean }>(`/organizations/${orgId}/favorite-boards/${id}`, headers)
}

export async function subscribeToFavoriteBoard(orgId: string, id: string) {
	const headers = await authHeader()
	return post<{ success: boolean }>(
		`/organizations/${orgId}/favorite-boards/${id}/subscribe`,
		headers
	)
}

export async function unsubscribeFromFavoriteBoard(orgId: string, id: string) {
	const headers = await authHeader()
	return del<{ success: boolean }>(
		`/organizations/${orgId}/favorite-boards/${id}/unsubscribe`,
		headers
	)
}
