import { Loading } from '@nextui-org/react'
import { rootStore } from '@store'
import type { BoardColumEnum, ICard } from '@touchpoints/requests'

import { GroupByFieldsEnum, useGroupedCards } from '@hooks/cards'
import type { BoardEntry } from '@types'
import { Dictionary, groupBy } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { BoardColumn } from './BoardColumn'
import { GroupedCards, IOrgData } from './GroupedCards'
import { useBoardLayout } from './hooks'
import { store } from './store'

export interface BoardViewWrapperProps {
	cards: ICard[]
	groupBy?: GroupByFieldsEnum[]
	loadingCards?: boolean
	renderLoadingCards?: number
	onClick?: (boardCard: ICard) => void
	selectedColumns?: BoardColumEnum[]
}

export function BoardViewWrapper({
	cards,
	groupBy = [],
	loadingCards = false,
	renderLoadingCards,
	onClick,
	selectedColumns,
}: BoardViewWrapperProps) {
	const router = useRouter()
	const params = useMemo(() => (router.query.params ?? []) as string[], [router])
	const positionCandidateId = useMemo(() => {
		if (params.length === 3) {
			// /accounts/:accountId/board/:positionCandidateId
			return params[2]
		}

		return undefined
	}, [params])
	const [positionId, candidateId] = useMemo(() => {
		if (params.length < 5) {
			return []
		}

		// /:accountId/board/:positionId/candidates/:candidateId
		return [params[2], params[4]]
	}, [params])

	useEffect(() => {
		const id = setTimeout(() => {
			if (!positionCandidateId && positionId && candidateId) {
				const pc = rootStore.positions.getPositionCandidate(candidateId, positionId)
				if (pc) {
					store.setSelectedPositionCandidateId(pc.id)
					return
				}

				rootStore.positions
					.fetchPositionCandidate(positionId, candidateId, {
						priority: 1,
					})
					.then((res) => {
						if (!res) {
							return
						}

						store.setSelectedPositionCandidateId(res.id)
					})
			} else if (positionCandidateId) {
				store.setSelectedPositionCandidateId(positionCandidateId)
			}
		}, 500)
		return () => clearTimeout(id)
	}, [positionCandidateId, positionId, candidateId])

	if (loadingCards && !renderLoadingCards) {
		return <Loading size="lg" className="mt-10" />
	}

	return (
		<div className="flex w-full h-full">
			<BoardView
				cards={cards}
				groupBy={groupBy}
				loadingCards={loadingCards}
				renderLoadingCards={renderLoadingCards}
				onClick={onClick}
				selectedColumns={selectedColumns}
			/>
		</div>
	)
}
export interface BoardViewProps {
	cards: ICard[]
	groupBy?: GroupByFieldsEnum[]
	loadingCards: boolean
	renderLoadingCards?: number
	onClick?: (boardCard: ICard) => void
	selectedColumns?: BoardColumEnum[]
}

const fakeCandidate = (): BoardEntry => {
	return {
		loading: true,
	}
}

export function BoardView({
	cards,
	groupBy: groupByFields,
	loadingCards,
	renderLoadingCards,
	onClick,
	selectedColumns,
}: BoardViewProps) {
	const [layout] = useBoardLayout()
	const groupedCards = useGroupedCards(
		cards,
		groupByFields?.length ? groupByFields : [GroupByFieldsEnum.position]
	)
	const [cardsByPosition, setCardsByPosition] = useState<Dictionary<BoardEntry[]> | undefined>(
		undefined
	)

	useEffect(() => {
		const grouped = groupBy(
			cards.map((bc) => {
				return {
					card: bc,
					loading: false,
				}
			}),
			(e) => e.card.position.id
		) as Dictionary<BoardEntry[]>

		if (!renderLoadingCards || renderLoadingCards < 1) {
			setCardsByPosition(grouped)
			return
		}

		const keys = Object.keys(grouped)
		let counter = renderLoadingCards

		while (counter > 0 && keys.length > 0) {
			const index = counter % keys.length
			grouped[keys[index]].push(fakeCandidate())
			counter--
		}

		setCardsByPosition(grouped)
	}, [cards, renderLoadingCards])

	const sortedEntiresByPosition = useMemo(() => {
		if (!cardsByPosition) {
			return []
		}

		return Object.keys(cardsByPosition).sort((a, b) => {
			const aPosition = rootStore.positions.getPositionById(a)
			const bPosition = rootStore.positions.getPositionById(b)
			if (!aPosition || !bPosition) {
				return 0
			}
			return bPosition.referenceNumber - aPosition.referenceNumber
		})
	}, [cardsByPosition])

	if (groupedCards.records.length === 0 || !cardsByPosition) {
		return (
			<div className="flex w-full h-full justify-center items-center">
				<p className="text-base text-slate-700">No cards</p>
			</div>
		)
	}

	const orgData: IOrgData = {
		positions: rootStore.positions._list.map((p) => {
			return {
				id: p.id,
				name: p.name,
				location: p.location,
				referenceName: p.referenceName,
				managerName: p.managerName,
				accountManagerId: p.accountManagerId,
			}
		}),
		accounts: rootStore.accounts.list.map((a) => {
			return {
				id: a.id,
				name: a.name,
			}
		}),
		orgUsers: rootStore.organizationUsers.usersForActiveOrg.map((u) => {
			return {
				id: u.id,
				firstName: u.firstName,
				lastName: u.lastName,
				email: u.email,
			}
		}),
		stages: rootStore.organizations.activeOrganization?.candidateStages ?? [],
	}

	return (
		<div className="flex flex-col w-full h-full">
			{layout === 'kanban' ? (
				<div className="w-full h-full overflow-x-auto scroll-pl-16 snap-x">
					<div className="flex w-full h-full">
						<div className="w-96 grid grid-flow-col gap-3">
							{sortedEntiresByPosition.map((positionId) => {
								return (
									<BoardColumn
										key={positionId}
										positionId={positionId}
										loadingCandidates={loadingCards}
										cards={cardsByPosition[positionId]}
										onClick={onClick}
									/>
								)
							})}
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col w-full h-full">
					<GroupedCards
						groupedCards={groupedCards}
						selectedColumns={selectedColumns}
						orgData={orgData}
					/>
				</div>
			)}
		</div>
	)
}
