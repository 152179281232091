import { orderBy } from 'lodash'
import { Query } from './query'

export function sortFavoriteBoards(boards: IFavoriteBoard[]): IFavoriteBoard[] {
	// const myPositions = boards.filter((e) => e.slug === 'my-positions')
	const theRest = orderBy(
		// boards.filter((e) => e.slug !== 'my-positions'),
		boards,
		['isPrivate', 'slug'],
		['desc', 'asc']
	)

	return [...theRest]
}

export interface IEmojiProps {
	emoji: string
	emojiUrl: string
	[key: string]: any
}

export enum BoardColumEnum {
	NAME = 'name',
	EMAIL = 'email',
	PHONE = 'phone',
	POSITION = 'position',
	RECRUITER = 'recruiter',
	ENGAGEMENT_CONTACT = 'engagementContact',
	ACCOUNT_EXECUTIVE = 'accountExecutive',
	SUPPORT_CONTACT = 'supportContact',
	STAGE = 'stage',
	NEXT_EVENT = 'nextEvent',
	PAST_EVENT = 'pastEvent',
	SUBSTATUS = 'substatus',
}

export const DEFAULT_SELECTED_COLUMNS = [
	BoardColumEnum.NAME,
	BoardColumEnum.EMAIL,
	BoardColumEnum.PHONE,
	BoardColumEnum.STAGE,
]

export interface IFavoriteBoard {
	id: string
	organizationId: string
	userOrgId: string
	isPrivate: boolean
	name: string
	emojiProps: IEmojiProps
	query: Query
	groupBy: string[]
	selectedColumns: BoardColumEnum[]
	subscribedUsers: string[]
	shareIds: string[]
}
